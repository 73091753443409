import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import  API from './Api';
import './assets/scss/style.scss';

// Views
import Chart from './components/Chart';
import Home from './views/Home';
import Signup from './views/Signup';
import OrderForm from './views/OrderForm';
import New from './views/New';
import News from './views/News';
import ResetEmail from './views/ResetEmail';
import ResetPassword from './views/ResetPassword';
import Profile from './views/Profile';
import Feed from './views/Feed';
import Admin from './views/Admin';
import Unsubscribe from './views/Unsubscribe';

import './App.css';

const App = () => {
  const navigate = useNavigate(); //breaking
  // persisting app state
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [dailySecret, setDailySecret] = React.useState("secret_undef");
  const [histSecret, setHistSecret] = React.useState("hist_undef");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userOnboarded, setUserOnboarded] = useState(false);
  const [influencer, setInfluencer] = useState(false);
  const [influencerKey, setInfluencerKey] = useState('');
  const [currency, setCurrency] = useState("");
  const [tags, setTags] = useState([]);
const onboard = () =>{
  setUserOnboarded(true);
}
  const login = (value) => {
    setUserLoggedIn(value);
    if (value){
      API({
    url: '/user/get'
  })
  .then(response => {
    if (response.statusText === "OK"){
      setName(response.data['name']);
      setDailySecret(response.data['dailySecret']);
      setHistSecret(response.data['histSecret']);
      setEmail(response.data['email']);
      setTags(response.data['tags']);
      if(response.data['currency'] != null)
      {
        setCurrency(response.data['currency']);
        console.log(currency);
      }
      if(response.data['onboarded'] === "false")
      {
        navigate("/new");
      }
      else{
	setUserOnboarded(true);
        navigate("/portfolio");
      }
      setInfluencer((response.data['influencer'] === "true"));
      setInfluencerKey((response.data['influencerKey']));
    }
  });
  };
  }

  useEffect(() => {
    API({
    url: '/user/get'
  })
  .then(response => {
    if (response.statusText === "OK"){
      setName(response.data['name']);
      setDailySecret(response.data['dailySecret']);
      setHistSecret(response.data['histSecret']);
      setUserLoggedIn(true);
      setEmail(response.data['email']);
      setTags(response.data['tags']);
      if(response.data['currency'] != null)
      {
        setCurrency(response.data['currency']);
        console.log(currency);
      }
      if(response.data['onboarded'] === "true"){
        setUserOnboarded(true);
      }
      setInfluencer((response.data['influencer'] === "true"));
      setInfluencerKey((response.data['influencerKey']));
    }
  }).catch(response =>{
    if(window.location.pathname === "/portfolio"){
      navigate("/");
    }
    setUserLoggedIn(false);
  });
  }, []);


if(userLoggedIn && userOnboarded){
  if (influencer){
    return (
        <Routes>
        <Route exact path="/" element={<Home setUserLoggedIn={setUserLoggedIn} loggedIn={true} onboarded={true} influencer={influencer} loggedInCallBack={login} />}/>
        <Route exact path="/portfolio" element={<Chart name={name} currency={currency} dailySecret={dailySecret} histSecret={histSecret} influencer={influencer}/>} />
        <Route exact path="/order" element={<OrderForm email={email} influencer={influencer} currencySymbol={currency} />} />
        <Route exact path="/news" element={<News email={email} influencer={influencer} influencerKey={influencerKey}/>} />
        <Route exact path="/profile" element={<Profile name={name} dailySecret={dailySecret} histSecret={histSecret} influencer={influencer} />} />
        <Route exact path="/admin" element={<Admin influencerKey={influencerKey} name={name} tags={tags} currency={currency} dailySecret={dailySecret} histSecret={histSecret} influencer={influencer}/>} />
        <Route exact path="/feed" element={<Feed tags={tags} loggedIn={userLoggedIn} onboarded={userOnboarded} influencer={influencer} />} />
        <Route exact path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
    )
  }else{
    return (
        <Routes>
	      <Route exact path="/" element={<Home setUserLoggedIn={setUserLoggedIn} loggedIn={true} onboarded={true} influencer={false} loggedInCallBack={login} />}/>
	      <Route exact path="/portfolio" element={<Chart name={name} currency={currency} dailySecret={dailySecret} influencer={false} histSecret={histSecret}/>} />
	      <Route exact path="/order" element={<OrderForm email={email} influencer={false} currencySymbol={currency} />} />
	      <Route exact path="/profile" element={<Profile name={name} dailySecret={dailySecret} histSecret={histSecret} influencer={influencer} />} />
        <Route exact path="/admin" element={<Admin influencerKey={influencerKey} name={name} tags={tags} currency={currency} dailySecret={dailySecret} histSecret={histSecret} influencer={influencer}/>} />
	      <Route exact path="/feed" element={<Feed tags={tags} loggedIn={userLoggedIn} onboarded={userOnboarded} influencer={influencer} />} />
        <Route exact path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
    )
  }
}else if(userLoggedIn && !userOnboarded){
  return (
        <Routes>
              <Route exact path="/" element={<Home setUserLoggedIn={setUserLoggedIn} loggedIn={true} onboarded={false} loggedInCallBack={login} />}/>
              <Route exact path="/portfolio" element={<Chart name={name} currency={currency} dailySecret={dailySecret} histSecret={histSecret}/>} />
              <Route exact path="/order" element={<OrderForm email={email} currencySymbol={currency}/>} />
          <Route exact path="/new" element={<New onboarded={onboard} />} />
          <Route exact path="/feed" element={<Feed tags={tags} loggedIn={userLoggedIn} onboarded={userOnboarded} influencer={influencer} />} />
          <Route exact path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
  )
}else{
  return (
        <Routes>

	      <Route exact path="/" element={<Home loggedInCallBack={login} loggedOutCallBack={login} userLoggedIn={userLoggedIn} />}/>
	      <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/reset_email" element={<ResetEmail />} />
        <Route exact path="/reset_password" element={<ResetPassword />} />
        <Route exact path="/feed" element={<Feed tags={tags}  loggedIn={userLoggedIn} onboarded={userOnboarded} influencer={influencer} />} />
        <Route exact path="/unsubscribe" element={<Unsubscribe />} />
        </Routes>
	)
}
}

export default App;

