import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Headline from './Headline'
import  API from '../../Api';
import InfiniteScroll from 'react-infinite-scroller';
import TextField from '@mui/material/TextField';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const  Testimonial = ({
  influencer,
  news,
  predictions,
  updateNews,
  updatePredictions,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  loggedIn,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

var page  = 0;
const [isSearchFeed, setIsSearchFeed] = React.useState(false);
const [search, setSearch] = React.useState("");
const [hasMoreToLoad, setHasMoreToLoad] = React.useState(true);
const [allIdeas, setAllIdeas] = React.useState([]);
const [scroller, setScroller] = React.useState(null);


const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value === ""){
    setIsSearchFeed(false);
    page = 0;
    setScroller(null,setScroller(renderScroll(),setAllIdeas([],setHasMoreToLoad(true,)))); 
  }
  else{
    setIsSearchFeed(true);
    page = 0;
    setScroller(null,setScroller(renderScroll(),setAllIdeas([],setHasMoreToLoad(true,)))); 
  }
  setSearch(event.target.value);
};
  
const fetchIdeas = async (page) => {
  setHasMoreToLoad(false);
  var feedUrl = '/influencer/'+influencer;
  var searchUrl = '/influencer/'+influencer+'/search/'+search.trim().toLowerCase();
  var url = '';
  if(isSearchFeed){
    url = searchUrl
  }else{
    url = feedUrl;
  }
  var newIdeas = allIdeas;
    console.log("fetching " + page);
        API({
      url: '/predictions' + url + '/page/'+ page 
      })
      .then(response => {
        newIdeas = newIdeas.concat(response.data);
        API({
          url: '/news' + url + '/page/'+ page
          })
          .then(response => {
            newIdeas = newIdeas.concat(response.data);
            let sorted = newIdeas.sort(compareByDate);
            setAllIdeas(sorted,
              setHasMoreToLoad(page<100))
            }
          )
          }
        );
};

function compareByDate( a, b ) {
  if ( a.date > b.date ){
    return -1;
  }
  if ( a.date < b.date ){
    return 1;
  }
  return 0;
}

function parseMonth(month){
  if(month === "01"){
    return "January";
  }
  if(month === "02"){
    return "Feburary";
  }
  if(month === "03"){
    return "march";
  }
  if(month === "04"){
    return "April";
  }
  if(month === "05"){
    return "May";
  }
  if(month === "06"){
    return "June";
  }
  if(month === "07"){
    return "July";
  }
  if(month === "08"){
    return "August";
  }
  if(month === "09"){
    return "September";
  }
  if(month === "10"){
    return "October";
  }
  if(month === "11"){
    return "November";
  }
  if(month === "12"){
    return "December";
  }
}

const ideasPadding = [
{
key: 0,
date: "2024/05/09 15:01:22",
feed: null,
headline: "████████████",
comment: "████████████",
link: "/",
tags: ['all']},
{key: 1,
  date: "2024/05/09 15:01:22",
feed: null,
comment: "████████████",
link: "/",
headline: "████████████",
tags: ['all']},
{key: 1,
  date: "2024/05/09 15:01:22",
feed: null,
comment: "████████████",
link: "/",
headline: "████████████",
tags: ['all']},
];

const renderScroll = () => {
    page = 0;
  return (<InfiniteScroll
    pageStart={0}
    loadMore={fetchIdeas}
    hasMore={hasMoreToLoad}
    threshold={800}>
    {<div className={tilesClasses}>{allIdeas.length > 0 ? allIdeas.map((x, index) => renderIdea(x, index)) : ideasPadding.map((x, index) => renderIdea(x, index))}</div>}
  </InfiniteScroll>)
}

function renderIdea(idea, index) {
  var isNews = false;
  if(idea['verdict'] == null){
  isNews = true;
  // this is news
  var date = idea['date'];
  var feed = 'https://illbeatwallstreet.com/feed?news='+ idea['newsId'];
  var comment = idea["comment"];
  var link = idea['link'];
  var headline = idea['title'];
  var tags = idea['tags'];
  }else{
  // prediction
  var date = idea['date'];
  var feed = 'https://illbeatwallstreet.com/feed?prediction='+ idea['predictionId'];
  var comment = idea["prediction"];
	var link = 'https://illbeatwallstreet.com/feed?influencer='+ idea['influencerKey'];
  var headline = "Verdict in " +parseMonth(idea['month'])+ " " + idea['year'];
  var tags = idea['tags'];
  var verdict = idea['verdict'];
  }
  return (<Headline key={index} date={date} feed={feed} comment={comment} headline={headline} link={link} tags={tags} isNews={isNews} verdict={verdict}/>)
}
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <ThemeProvider theme={darkTheme}>
          <div className="center-content">
        <TextField sx={{ p: 1, width : '25ch'}} id="standard-basic" label={"Search"} variant="standard" value={search} onChange={handleSearch} />
</div>
</ThemeProvider>
<>
{scroller!=null? scroller : <InfiniteScroll
    pageStart={0}
    loadMore={fetchIdeas}
    hasMore={hasMoreToLoad}
    threshold={800}>
    {<div className={tilesClasses}>{allIdeas.length > 0 ? allIdeas.map((x, index) => renderIdea(x, index)) : ideasPadding.map((x, index) => renderIdea(x, index))}</div>}
  </InfiniteScroll>}
</>
      </div>
    </section>
  );
}


Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;




