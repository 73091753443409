import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import {View, Image, StyleSheet} from 'react-native';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
//import Reset from '../views/ResetEmail';
import { useNavigate } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import marketImg from './../../market.png';
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const graph = StyleSheet.create({
  profile: {
    width: "100%",
    height: undefined,
    aspectRatio: 1.9,
    borderRadius: 10,
  },
});

const Hero = ({
  name,
  setUserLoggedIn,
  loggedIn,
  loggedInCallBack,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
   const [userEmail, setEmail] = useState("");
   const [userPassword, setPassword] = useState("");
  const navigate = useNavigate();

const Submit = (event) => {
  event.preventDefault();
  fetch('/api/user/login', {
    method: 'POST',
    body: JSON.stringify({"email": userEmail, "password": userPassword}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
      //const response = userDetails();
      loggedInCallBack(true);
    } else {
      //TODO incorrect password modal
    }
  }).catch(response =>{
    console.log("login failed");
    loggedInCallBack(false);
  });
}

const ResetEmail = () => {
  console.log("reset email");
  navigate("/reset_email");
}

const emailHandler = (event) =>{
	 event.preventDefault();
   setEmail(event.target.value.toLowerCase());
 }

const passwordHandler = (event) =>{
	 event.preventDefault();
   setPassword(event.target.value);
 }

const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-lg">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              I'll beat Wall Street
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                crowd sourced portfolio benchmarking
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                { !loggedIn &&
<ThemeProvider theme={darkTheme}>
<Box
      component="form"
      onSubmit={Submit}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="on"
>
<TextField id="outlined-basic" label="Email" variant="outlined" value={userEmail} onChange={emailHandler} />
<TextField id="outlined-basic" label="Password" type="password" variant="outlined" value={userPassword} onChange={passwordHandler} />
<br/>
<Button type="submit"
onClick={Submit}
>
Log In
</Button>
<Button type="submit"
onClick={ResetEmail}>
Reset
</Button>
</Box>
</ThemeProvider>
              }
              </div>
            </div>
          </div>
          <div style={{padding:10}}></div>
              <Image
                style={graph.profile}
                source={
                  marketImg
                }
              />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;

