import { useState } from 'react';
import  API from '../Api';
import Header from '../components/layout/Header';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactGA from "react-ga4";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { color } from 'highcharts';

export default function Form() {

ReactGA.initialize("G-WQP2CKSW84");
const [disableButton, setDisableButton] = useState(false);
// States for registration
const [email, setEmail] = useState('');

// States for checking the errors
const [submitted, setSubmitted] = useState(false);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const unsub = async (event) =>
 {
   fetch('/api/user/unsubscribe/'+email, {
    method: 'POST',
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }}
    )
    .then(res => {
	    setSubmitted(true);
      setDisableButton(true);
	   
	  }
    ).catch(response =>{
      setSubmitted(true);
      setDisableButton(true);
    }
  );
 }

// Handling the email change
const handleEmail = (e) => {
	setEmail(e.target.value);
	if(e.target.value != ""){
	    setDisableButton(false);
    }
};

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? '' : 'none',
        }}>
        <p>You will no longer recieve emails from I'llBeatWallstreet. Sorry to see you go.</p>
      </div>
    );
  };

  return (
	  <>
          <ThemeProvider theme={darkTheme}>
	      <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
    <div style={{ paddingTop: '100px' }}>
    <div className="form">
    <div className="container-xs">
    <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
    If you wish to no longer recieve emails from I'll Beat Wallstreet, enter your email below and unsubscribe
    </h3>


    <div style={{ paddingTop: '5px', margin: 'auto' }}/>
    <div className="container-lg">


<Box
      onSubmit={unsub}
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 0, p: 1, width: '95%', maxWidth : '40ch' },
        flexWrap: 'wrap'
      }}
      noValidate
      autoComplete="off"
    >
<Box>
<TextField id="outlined-basic" label="Email" variant="outlined" value={email} onChange={handleEmail} />
</Box>
<br/>
<Button
disabled={disableButton}
onClick={unsub}
>
Submit 
</Button>
</Box>
      {/* Calling to the methods */}
      <div className="messages">
        {successMessage()}
      </div>
</div>
</div>
</div>
	  </div>
	  </main>
</ThemeProvider>
</>
  );
}



