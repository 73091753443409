//import * as React from 'react';
import PredictionsSummary from './sections/PredictionsSummary';
import Box from '@mui/material/Box';
import  API from './Api';
import React, { useEffect, useState } from 'react';


const InfluencerPredictionDisplay = ({influencer, updateNews, updatePredictions}) => {
const [predictions, setPredictions] = React.useState([]);
    useEffect(() => {
        API({
            url: '/predictions/influencer/' + influencer
            })
            .then(response => {
              setPredictions(response.data);
            });
      }, []);
      

return(
<>
<Box
  sx={{
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    p: 1,
    m: 1,
    bgcolor: 'background.paper',
    borderRadius: '40px',
  }}>
      <PredictionsSummary topDivider={false} influencer={influencer} NewsList={[]} predictions={predictions} updateNews={updateNews} updatePredictions={updatePredictions}/>
</Box>
</>
);
}
export default InfluencerPredictionDisplay;

