


const YearBadgeWidget = ({year, netPercentage, percentile}) => {

    function toHex(number){
        var rounded = Math.round(number);
        if(rounded < 10){
            return "0" + rounded;
        }else{
            return rounded.toString(16);
        }
    }

    function calculateColor(percentage){
        if (percentage < -50){
            return "#c80000";
        }else if (percentage < 0 && percentage >= -50){
            return "#c8" + toHex(200+(percentage*2)) + toHex(220+(percentage*2));
        }else if (percentage == 0){
            return "#c8c8c8";
        }else if (percentage > 0 && percentage <= 50){
            return "#" + toHex(200-(percentage*2)) + "c8" + toHex(220-(percentage*2));
        }else if (percentage > 50 && percentage <= 100){
            return "#" + "00" + "c8" + toHex(120+((percentage-50)*2));
        }else if (percentage >100){
            return "#00c8c8";
        }

    }

    var colour = calculateColor(netPercentage);
    var circleStyle = null;
    if (year == "Per Year"){
    circleStyle = {
        padding:10,
        margin:3,
        display:"inline-block",
        backgroundColor:colour,
        borderRadius: "50%",
        border:1,
        width:90,
        height:120
    };
    }else{
        circleStyle = {
        padding:10,
        margin:2,
        display:"inline-block",
        backgroundColor:colour,
        borderRadius: "50%",
        width:70,
        height:100
    };
    }

    if (year == "Per Year"){
    var yearStyle = {
        fontWeight: 'bold',
        fontSize:17,
        padding:0,
        margin:0,
        marginTop:'2px',
        textAlign: 'center',
        color: 'black',
    };
    }else{
        var yearStyle = {
            fontWeight: 'bold',
            fontSize:15,
            padding:0,
            margin:0,
            marginTop:'-3px',
            textAlign: 'center',
            color: 'black',
        }; 
    }
    if (year == "Per Year"){
    var topStyle = {
        paddingTop:1,
        marginTop:'5px',
        marginBottom:0,
        marginLeft:'5px',
        textAlign: 'center',
        color: 'black',
    };
    }else{
    var topStyle = {
        paddingTop:1,
        marginTop:0,
        marginBottom:0,
        marginLeft:'3px',
        textAlign: 'center',
        color: 'black',
    }; 
    }

    if (year == "Per Year"){
    var bottomStyle = {
        textAlign: 'center',
        color: 'black',
        marginTop:'0px',
        marginLeft:'8px',
    };
    }else{
    var bottomStyle = {
        textAlign: 'center',
        color: 'black',
        marginTop:'-3px',
        marginLeft:'3px',
    }; 
    }

    return(
        <>
        <div style={circleStyle}>
            <p style={topStyle}>{netPercentage}%</p>
            <p style={yearStyle}>{year}</p>
            <p style={bottomStyle}>{percentile}p<sup>th</sup></p>
        </div>
        </>
    );

};
export default YearBadgeWidget;

