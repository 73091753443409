import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faPenNib, faShareNodes }from '@fortawesome/free-solid-svg-icons'
import { RWebShare } from "react-web-share";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


const subscribe = (tag, userTags) => {
  userTags.push(tag);
  fetch('/api/user/updateTags', {
    method: 'POST',
    body: JSON.stringify({"tags": userTags}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
      window.location.reload();
    } else {
    }
  }).catch(response =>{
  });
}

const unsubscribe = (tag, userTags) => {
  userTags = userTags.filter(e => e !== tag);
  fetch('/api/user/updateTags', {
    method: 'POST',
    body: JSON.stringify({"tags": userTags}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
      window.location.reload();
    } else {
    }
  }).catch(response =>{
  });
}

function renderTag (tag, isSubscribed, userTags){
  if(isSubscribed){
    return(
      <Button style={{margin:'5px'}} variant="outlined" color="success" size="small"
      onClick={() => {unsubscribe(tag, userTags)}}
      >
      {tag}
      </Button>
    );
  }else{
    return(
      <Button style={{margin:'5px'}} variant="outlined" color="error" size="small"
      onClick={() => {subscribe(tag, userTags)}}
      >
      {tag}
      </Button>
  );
  }
}

function renderAllTags(tags, userTags){
  return(
    <>
      {tags.map((tag) => (renderTag(tag ,userTags.includes(tag), userTags)))}
    </>
  );
}

function parseMonth(month){
  if(month === "01"){
    return "January";
  }
  if(month === "02"){
    return "Feburary";
  }
  if(month === "03"){
    return "march";
  }
  if(month === "04"){
    return "April";
  }
  if(month === "05"){
    return "May";
  }
  if(month === "06"){
    return "June";
  }
  if(month === "07"){
    return "July";
  }
  if(month === "08"){
    return "August";
  }
  if(month === "09"){
    return "September";
  }
  if(month === "10"){
    return "October";
  }
  if(month === "11"){
    return "November";
  }
  if(month === "12"){
    return "December";
  }
}

const HeadlineFullScreen = ({userTags, date, feed, name, headline, comment, link, tags, isNews}) => {
  let withoutProtocol = link.replace(/https?:\/\//i, "")
  let domain = withoutProtocol.substring(0, withoutProtocol.indexOf("/"));
return (
  <>
        <div className="tiles-item-inner" style={{backgroundColor: "#202020", borderRadius: '40px'}}>
          <FontAwesomeIcon icon={faLightbulb} size="lg" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: '#202020',
            borderRadius: 1,
          }}
        >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            p: 1,
            m: 1,
            bgcolor: '#202020',
            borderRadius: 1,
          }}
        
        >
<RWebShare
	data={{
		text: headline,
		url: feed,
		title: headline,
	}}
>
<Button>Share <FontAwesomeIcon icon={faShareNodes} size="lg" /></Button>
</RWebShare>
  {(userTags.length > 0) && renderAllTags(tags, userTags)}
  </Box>
  <i>{parseMonth(date.substring(5,7)) + " " + date.substring(8,10) + " " + date.substring(0,4)}</i>
  <blockquote style={{fontSize:'30px', color:'white'}}>{headline}</blockquote>
    <p style={{whiteSpace: 'pre-line'}} className="text-sm mb-0">
      {comment}
        </p>
        <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
    <span className="testimonial-item-link">
    {isNews && (link.length >0) && <a href={link}>{domain}</a> }
    </span> 
  </div>
  </Box>
</div>
</>
  )
}
export default HeadlineFullScreen
