import Box from '@mui/material/Box';
import VoteIcon from './VoteIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenNib, faShareNodes }from '@fortawesome/free-solid-svg-icons'
import { RWebShare } from "react-web-share";
import Button from '@mui/material/Button';


const subscribe = (tag, userTags) => {
  userTags.push(tag);
  fetch('/api/user/updateTags', {
    method: 'POST',
    body: JSON.stringify({"tags": userTags}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
      window.location.reload();
    } else {
    }
  }).catch(response =>{
  });
}

const unsubscribe = (tag, userTags) => {
  userTags = userTags.filter(e => e !== tag);
  fetch('/api/user/updateTags', {
    method: 'POST',
    body: JSON.stringify({"tags": userTags}),
    credentials: 'include',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
    }
  })
  .then(res => {
    if (res.status === 200) {
      window.location.reload();
    } else {
    }
  }).catch(response =>{
  });
}

function renderTag (tag, isSubscribed, userTags){
  if(isSubscribed){
    return(
      <Button style={{margin:'5px'}} variant="outlined" color="success" size="small"
      onClick={() => {unsubscribe(tag, userTags)}}
      >
      {tag}
      </Button>
    );
  }else{
    return(
      <Button style={{margin:'5px'}} variant="outlined" color="error" size="small"
      onClick={() => {subscribe(tag, userTags)}}
      >
      {tag}
      </Button>
  );
  }
}

function renderAllTags(tags, userTags){
  return(
    <>
      {tags.map((tag) => (renderTag(tag ,userTags.includes(tag), userTags)))}
    </>
  );
}

const PredictionDetailView = ({displayName, prediction, userTags}) => {
//console.log(prediction[0]);
prediction = prediction[0]

var from = Date.parse(prediction['date']);
var to = Date.parse(prediction["year"] + "/" + prediction['month'] + "/01");
//console.log(from);
//console.log(to);
var fromDate = new Date(from);
//console.log(fromDate);
//console.log(fromDate.getMonth()+1);
var daysBetween = Math.round((to - from) / (1000 * 60 * 60 * 24));
//console.log(daysBetween);
//console.log("day is " + fromDate.getDate());

function parseIntMonth(month){
  if(month === 1){
    return "January";
  }
  if(month === 2){
    return "Feburary";
  }
  if(month === 3){
    return "March";
  }
  if(month === 4){
    return "April";
  }
  if(month === 5){
    return "May";
  }
  if(month === 6){
    return "June";
  }
  if(month === 7){
    return "July";
  }
  if(month === 8){
    return "August";
  }
  if(month === 9){
    return "September";
  }
  if(month === 10){
    return "October";
  }
  if(month === 11){
    return "November";
  }
  if(month === 12){
    return "December";
  }

}

function parseMonth(month){
  if(month === "01"){
    return "January";
  }
  if(month === "02"){
    return "Feburary";
  }
  if(month === "03"){
    return "march";
  }
  if(month === "04"){
    return "April";
  }
  if(month === "05"){
    return "May";
  }
  if(month === "06"){
    return "June";
  }
  if(month === "07"){
    return "July";
  }
  if(month === "08"){
    return "August";
  }
  if(month === "09"){
    return "September";
  }
  if(month === "10"){
    return "October";
  }
  if(month === "11"){
    return "November";
  }
  if(month === "12"){
    return "December";
  }

}
    return(
        <>
        <div className="tiles-item-inner" style={{backgroundColor: "#202020", borderRadius: '40px'}}>
          <FontAwesomeIcon icon={faPenNib} size="lg" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: '#202020',
            borderRadius: 1,
          }}
        >


        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'row',
            p: 1,
            m: 1,
            bgcolor: '#202020',
            borderRadius: 1,
          }}
          
        >
          <RWebShare
            data={{
              text: parseMonth(prediction['month']) + ' '+ prediction['year'] + ' prediction by '+ displayName,
              url: 'https://illbeatwallstreet.com/feed?prediction='+prediction['predictionId'],
              title: parseMonth(prediction['month']) + ' ' + prediction['year'] + ' prediction by '+ displayName,
            }}
        >
          <Button>Share <FontAwesomeIcon icon={faShareNodes} size="lg" /></Button>
        </RWebShare>
          {(userTags.length > 0) && renderAllTags(prediction['tags'], userTags)}
        </Box>

          <i>{"On " + parseIntMonth(fromDate.getMonth()+1) + " " + fromDate.getDate() + " " + fromDate.getUTCFullYear()}</i>
          <i>{"By " + parseMonth(prediction['month']) + " " + prediction['year']} <b><span style={{fontSize:'25px', color:'gray'}}> {"(" + daysBetween + " days)"}</span></b></i>
          <blockquote style={{fontSize:'30px', color:'white', whiteSpace: 'pre-line'}}>{prediction['prediction']}</blockquote>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
              p: 1,
              m: 1,
              bgcolor: '#202020',
              borderRadius: 1,
            }}
          >
          <VoteIcon vote={prediction['verdict']} size={120}/>
          {prediction['votes'].map((vote) => (<VoteIcon vote={vote} size={24}/>))}
          </Box>
      </Box>
      </div>
       </>
    );

};
export default PredictionDetailView;
